<template>
  <div>
    <h5 v-if="pendingRegistrations != null && pendingRegistrations.length === 0">Nema novih prijava.</h5>
    <h5 v-else>Prijave za {{ grade }}:</h5>
    <div class="registrations-container"
         @scroll="handleScroll"
         :class="{'no-mask': isAtBottom}">
      <div class="registration"
           v-for="(registration, index) in pendingRegistrations"
           :key="index"
           :class="{'approved-registration': registration.approved, 'rejected-registration': registration.rejected && !registration.approved}">
        <div class="parent-data">
          <p>{{ registration.parentName }}</p>
          <p>{{ registration.studentName }}</p>
          <p>{{ registration.phone }}</p>
        </div>
        <div class="actions">
          <b-checkbox v-model="registration.approved" :native-value="true"
                      @input="handleCheckboxChange(registration, 'approved')"
                      type="is-success">Potvrdi
          </b-checkbox>
          <b-checkbox v-model="registration.rejected" :native-value="true"
                      @input="handleCheckboxChange(registration, 'rejected')">Odbij
          </b-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BaseValue from "@mindsmiths/armory-sdk/src/components/base/BaseValue";

export default {
  name: "PendingParentRegistrations",
    extends: BaseValue,
  props: {
    inputId: String,
    pendingRegistrations: Array,
    grade: String
  },
  data() {
    return {
      isAtBottom: false,
    };
  },
    mounted() {
    this.inputValue = this.pendingRegistrations;
    console.log(this.pendingRegistrations)
    this.resizeDescription();
    window.addEventListener("resize", this.resizeDescription);
    this.handleScroll();

  },
  methods: {
    handleCheckboxChange(registration, type) {
      if (type === 'approved' && registration.approved) {
        registration.rejected = false;
      } else if (type === 'rejected' && registration.rejected) {
        registration.approved = false;
      }
      this.inputValue = this.pendingRegistrations;
    },
    handleScroll() {
      const container = document.querySelector(".registrations-container");
      const isAtBottom = container.scrollHeight - container.scrollTop <= container.clientHeight + 1; // +1 for rounding issues
      this.isAtBottom = isAtBottom;
    },
    resizeDescription() {
      const headerGroup = document.querySelector("#HeaderGroup--internal");
      const registrations = document.querySelector(".registrations-container");

      registrations.style.height = 0;

      const headerComponentsHeight = headerGroup.clientHeight;

      const app = document.querySelector("#app");
      const appPadding =
          parseFloat(window.getComputedStyle(app).paddingBottom) +
          parseFloat(window.getComputedStyle(app).paddingTop);

      const componentMargin = parseFloat(
          window.getComputedStyle(document.querySelector(".component"))
              .marginBottom
      );

      const footer = document.querySelector("#FooterGroup--internal");
      const footerHeight = footer ? footer.clientHeight : 0;

      const legalElement = document.querySelector(".legal");
      const legalHeight = legalElement
          ? legalElement.clientHeight +
          parseFloat(window.getComputedStyle(legalElement).marginTop)
          : 0;

      registrations.style.height = `calc(100svh - ${
          headerComponentsHeight +
          appPadding +
          componentMargin +
          footerHeight +
          legalHeight
      }px)`;
    },
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/skin.scss";

.registrations-container {
  overflow-y: auto; /* Enables vertical scrolling */
  padding-right: 0.3rem; /* Matches the padding inside the registration boxes */

  /* Optional: Adds a blurred effect to the bottom of the container */
  mask-image: linear-gradient(to bottom, black calc(100% - 50px), transparent 110%);
}
.no-mask {
  mask-image: none; /* Removes the mask */
}

.registration {
  background-color: #f5f5f5; /* Light gray background */
  padding: 0.5rem; /* Adds space inside the box */
  margin-bottom:0.5rem; /* Adds space between registration boxes */
  border: 1px solid #e0e0e0; /* Light gray border for better division */
  border-radius: 8px; /* Optional: Adds rounded corners to the boxes */
  display: flex; /* Enables Flexbox */
  justify-content: space-between; /* Pushes children to opposite ends */
  align-items: center; /* Aligns children vertically in the center */
}

.actions {
  gap: 1rem;
  font-size: medium;
}

p {
  font-size: small;
  margin-bottom: 0.0rem !important; /* Reduced bottom margin to bring text elements closer */
}

.parent-data, .actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.parent-data {
  p:first-of-type {
    font-weight: bold; /* Makes the font bold */
    font-size: medium;
  }
}

/* New styles for approved and rejected states */
.approved-registration {
  background-color: #d3f8d3; /* Lighter green for approved */
}

.rejected-registration {
  background-color: #f8d3d3; /* Lighter red for rejected */
}

</style>