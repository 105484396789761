<template>
  <div class="component content VerificationCode">
    <div class="pin-input">
      <input
        v-for="(code, index) in codeInput"
        :key="index"
        v-model="codeInput[index]"
        @keydown="handleKeyDown(index, $event)"
        @input="handleInput(index, $event)"
        name="code"
        type="number"
        class="code-input"
        :class="{ 'error-message visible': showWrongCodeMessage }"
        required
        ref="codeInput"
        maxlength="1"
      />
    </div>
    <div class="actions">
      <div :class="{'error-message' : true, visible : (showWrongCodeMessage || limitExceeded)}">
        <span v-if="showWrongCodeMessage">{{ invalidCodeMessage }}</span>
        <span v-else-if="limitExceeded">{{ limitExceededMessage }}</span>
      </div>

      <b-notification :closable="false">
        <b-loading v-model="showLoading"></b-loading>
      </b-notification>
    </div>
  </div>
</template>

<script>
import BaseSubmitButton from "@mindsmiths/armory-sdk/src/components/base/BaseSubmitButton.vue";
import BaseValue from "@mindsmiths/armory-sdk/src/components/base/BaseValue.vue";

export default {
  name: "VerificationCode",
  extends: BaseValue,
  props: {
    numberOfDigits: {
      type: Number,
      default: 4,
    },
    invalidCodeMessage: {
      type: String,
      default: "Invalid code",
    },
    wrongCode: String,
    limitExceeded: Boolean,
    limitExceededMessage: {
      type: String,
      default: "You've hit a limit. Try again in an hour.",
    },
  },
  data() {
    return {
      text: "",
      codeInput: Array(this.numberOfDigits).fill(""),
      showLoading: false,
      showWrongCodeMessage: false,
      transitionDirection: "right",
    };
  },
  mounted() {
    this.$refs.codeInput[0].focus();
    // Hack to fix the animation
    setTimeout(() => {
      this.$el.querySelector(".pin-input").classList.add("loaded");
    }, 100);

    if (this.wrongCode) {
      this.showWrongCodeMessage = true;
      this.codeInput = Array.from(this.wrongCode);
      this.$el.querySelector(".pin-input").classList.add("wrong-code");
      setTimeout(() => {
        this.showWrongCodeMessage = false;
        this.codeInput = Array(this.numberOfDigits).fill("");
        this.$el.querySelector(".pin-input").classList.remove("wrong-code");
      }, 3000);
    }
  },
  methods: {
    ...BaseSubmitButton.methods,
    handleKeyDown(index, event) {
      // handles delete key
      if (event.keyCode === 8 && event.target.value === "" && index > 0) {
        this.$refs.codeInput[index - 1].focus();
      }
    },
    handleInput(index, event) {
      const [first, ...rest] = event.target.value;
      this.codeInput[index] = first ?? "";
      if (rest.length > 0) {
        this.spillover(rest, index);
      }
      // get first empty input
      const emptyInputIndex = this.codeInput.findIndex((value) => value === "");
      if (emptyInputIndex < this.codeInput.length && emptyInputIndex >= 0) {
        this.$refs.codeInput[emptyInputIndex].focus();
      }
      this.text = this.codeInput.join("");
      if (this.codeInput.every((value) => value !== "")) {
        this.clicked();

      }
    },
    spillover(chars, index) {
      chars.forEach((char, i) => {
        if (index + i + 1 < this.codeInput.length) {
          this.codeInput[index + i + 1] = char;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/skin.scss";

div.VerificationCode {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.pin-input {
  display: flex;
  width: 100%;
  gap: 16px;
}

input {
  outline: none;
  padding: 0;
  border: none;
  min-width: 0;
  width: 1%;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}
.pin-input.loaded > input {
  border-bottom: 3px solid hsl(0, 0%, 86%);
  width: 100%;
  font-size: 3em;
  height: 4rem;
  padding: 0;
}
.pin-input.wrong-code > input {
  color: #d51010;

}
div.error-message {
  height: 24px;
  margin-bottom: 0;
  transition: opacity 0.5s ease-in-out;
  color: #d51010
}
.error-message:not(.visible) {
  opacity: 0;
}
.error-message.visible {
  opacity: 1;
}

</style>
