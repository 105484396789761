<template>
  <div class="Input">
    <div class="field">
      <label class="label" v-if="label">{{ label }}</label>
      <div class="description-text" v-if="descriptionText" v-html="descriptionText"></div>
      <div class="control">
        <input
            v-model="inputValue"
            :placeholder="placeholder"
            :type="type"
            class="input"
            @input="$event.target.composing = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseValue from "@mindsmiths/armory-sdk/src/components/base/BaseValue.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Input",
  extends: BaseValue,
  props: {
    placeholder: String,
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      required: false,
    },
    descriptionText: {
      type: String,
      required: false,
    },
  },
};
</script>


<style scoped lang="scss">

.description-text {
  line-height: 1.2;
  font-size: 0.9rem;
  color: grey;
  margin-bottom: 0.6rem;
}
</style>